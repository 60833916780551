/* Ratio. */
.h-ratio {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  &--16-by-9 {
    &::before {
      @include aspect-ratio(16, 9);

      content: '';
      display: block;
    }
  }

  &--4-by-3 {
    &::before {
      @include aspect-ratio(16, 9);

      content: '';
      display: block;
    }
  }

  &__item {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
