@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(63deg);
  }
}

@keyframes stroke {
  to {
    stroke-dashoffset: 453;
  }
}

.m-bar {
  position: relative;

  @include breakpoint-down(small) {
    height: $space-container * 2;
  }

  @include breakpoint-down(tiny) {
    height: $space-container;
  }

  &__ellipse {
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    overflow: visible;

    @include breakpoint-down(tiny) {
      width: 150px;
      height: auto;
    }

    &-meter {
      stroke-dasharray: 720;
      stroke-dashoffset: 310;

      &--reveal {
        animation: stroke 1.5s forwards;
        animation-timing-function: $ease-out-quart;
      }
    }
  }

  &__line {
    fill: none;
    stroke: #fbf9f9;
    transform-box: fill-box;
    transform-origin: center;
    transform: translateX(-26px);
    transition: transform 0.1s linear;

    &--reveal {
      animation: rotate 1.5s linear forwards;
      animation-timing-function: $ease-out-quart;

      transform: translateX(0);
    }
  }

  &__counter {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    &::after {
      content: '%';
    }
  }
}
