.h-text {
  &__title {
    font-weight: $font-weight-bold;
    line-height: 1.1;
    font-size: 1.25rem;

    @include breakpoint-up(tiny) {
      font-size: 1.6rem;
    }

    @include breakpoint-up(medium) {
      font-size: 2.1875rem;
    }

    &--small {
      @extend .h-text__title;
      font-size: 1.2rem;

      @include breakpoint-up(tiny) {
        font-size: 1.5rem;
      }

      @include breakpoint-up(medium) {
        font-size: 1.875rem;
      }
    }

    &--large {
      @extend .h-text__title;
      font-size: 1.35rem;

      @include breakpoint-up(tiny) {
        font-size: 1.75rem;
      }

      @include breakpoint-up(medium) {
        font-size: 2.8125rem;
      }
    }
  }

  &__intro {
    font-size: 1.125rem;
    line-height: 1.125;

    &--green {
      background: $color-secondary;
      padding: 1.5rem 2rem;
      border-radius: 10px;
      color: $color-primary;
    }

    @include breakpoint-up(medium) {
      line-height: 1.25;
    }
  }

  &__paragraph {
    font-size: 1rem;

    @include breakpoint-up(medium) {
      font-size: 1.125rem;
    }
  }

  &__header {
    line-height: 1.1;
    font-size: 2rem;

    @include breakpoint-up(tiny) {
      font-size: 2.5rem;
    }

    @include breakpoint-up(small) {
      font-size: 3.125rem;
    }

    @include breakpoint-up(medium) {
      font-size: 5.4rem;
    }
  }

  &__swoosh {
    position: relative;

    &::after {
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, $space-large, 0);
    }

    &--white {
      @extend .h-text__swoosh;

      &::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='173' height='13' viewBox='0 0 173 13' fill='none'%3E%3Cpath d='M2.60742 9.8949C58.5919 4.86339 113.881 1.24775 169.939 4.94614' stroke='white' stroke-width='5' stroke-linecap='round'/%3E%3C/svg%3E");
      }
    }

    &--green {
      @extend .h-text__swoosh;

      &::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='173' height='13' viewBox='0 0 173 13' fill='none'%3E%3Cpath d='M2.60742 9.92334C58.5919 4.89184 113.881 1.2762 169.939 4.97459' stroke='%2385FA9E' stroke-width='5' stroke-linecap='round'/%3E%3C/svg%3E");
      }
    }
  }

  &__highlight {
    z-index: 2;

    &--highlighted {
      z-index: 2;
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        left: -2.5%;
        width: 108%;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 44' fill='none'%3E%3Cpath d='M15.3334 28.2774C66.9996 16.0001 114.999 14 184.999 16' stroke='%2358FF7B' stroke-width='30' stroke-linecap='round'/%3E%3C/svg%3E");
        z-index: -1;
      }
    }
  }
}
