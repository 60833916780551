.m-content {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease-out-quart;
  visibility: hidden;
  will-change: transform;

  &.o-header__content {
    transform: translate3d(0, calc(-40% + 20px), 0);
  }

  &--visible {
    min-height: var(--layout-space);
  }

  &.u-opacity--50 {
    opacity: 0 !important;
  }

  &[data-direction='none'] {
    transform: translate3d(0, 0, 0);
    transition: opacity 0.8s $ease-out-cubic;
  }

  &--reveal {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;

    &.o-header__content {
      transform: translate3d(0, -40%, 0);
    }

    &.u-opacity--50 {
      opacity: 0.5 !important;
    }
  }
}
