/* Margin spacing top. */
.u-spacing-mt--collapse {
  margin-top: 0 !important;

  &-at-min-medium {
    @include breakpoint-up(medium) {
      margin-top: 0 !important;
    }
  }
}
.u-spacing-mt--small {
  margin-top: $space-small !important;
}
.u-spacing-mt--medium {
  margin-top: $space-base !important;
}
.u-spacing-mt--large {
  margin-top: $space-large !important;
}

.u-spacing-mt--huge {
  margin-top: $space-huge !important;

  @include breakpoint-down(medium) {
    margin-top: $space-huge * 0.5 !important;
  }
}

.u-spacing-mt--container {
  margin-top: $space-container * 0.5 !important;

  @include breakpoint-up(medium) {
    margin-top: $space-container * 0.75 !important;
  }

  @include breakpoint-up(large) {
    margin-top: $space-container !important;
  }

  &-at-small {
    @include breakpoint-down(small) {
      margin-top: $space-container * 0.5 !important;
    }
  }

  &-at-medium {
    @include breakpoint-down(medium) {
      margin-top: $space-container * 0.75 !important;
    }

    @include breakpoint-down(small) {
      margin-top: $space-container * 0.5 !important;
    }
  }
}

/* Margin spacing bottom. */
.u-spacing-mb--collapse {
  margin-bottom: 0 !important;
}
.u-spacing-mb--small {
  margin-bottom: $space-small !important;
}
.u-spacing-mb--medium {
  margin-bottom: $space-base !important;
}
.u-spacing-mb--large {
  margin-bottom: $space-large !important;
}

/* Padding spacing top. */
.u-spacing-pt--collapse {
  padding-top: 0 !important;
}
.u-spacing-pt--small {
  padding-top: $space-small !important;
}
.u-spacing-pt--medium {
  padding-top: $space-base !important;
}
.u-spacing-pt--large {
  padding-top: $space-large !important;
}

/* Padding spacing bottom. */
.u-spacing-pb--collapse {
  padding-bottom: 0 !important;
}
.u-spacing-pb--small {
  padding-bottom: $space-small !important;
}
.u-spacing-pb--medium {
  padding-bottom: $space-base !important;
}
.u-spacing-pb--large {
  padding-bottom: $space-large !important;
}
