/* Tabs. */
.m-tabs {
  margin: $space-small 0 $space-base;

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    & + & {
      margin-top: $space-small;
    }

    &::after {
      background-color: $color-default;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  &__item {
    margin: 0;
    padding: 0;
  }

  &__tab {
    border-radius: $border-radius $border-radius 0 0;
    display: block;
    font-weight: $font-weight-bold;
    line-height: $line-height-small;
    padding: $space-base;
    position: relative;
    text-decoration: none;

    &:hover {
      background-color: tint($color-link, 90%);
    }

    .m-tabs__item--is-active & {
      &::after {
        background-color: $color-link;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
      }
    }
  }
}
