.h-stack {
  &--text {
    & > * {
      margin-top: $space-base * 1.5;

      &:first-child {
        margin-top: 0;
      }
    }

    &-large {
      & > * {
        margin-top: $space-base * 2.5;

        @include breakpoint-down(medium) {
          margin-top: $space-base * 1.5;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &--form {
    & > * {
      &:nth-child(2) {
        margin-top: $space-huge;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    & .m-form__message {
      & > * {
        margin-top: $space-small;

        &:nth-child(2) {
          margin-top: $space-huge;
        }

        &:last-child {
          margin-top: $space-large;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    & .m-form__succes {
      & > * {
        margin-top: $space-base;

        &:nth-child(2) {
          margin-top: $space-large;
        }

        &:last-child {
          margin-top: $space-large;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
