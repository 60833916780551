/* Text align. */
.u-text-align--left {
  text-align: left !important;
}
.u-text-align--right {
  text-align: right !important;
}
.u-text-align--center {
  text-align: center !important;

  &-at-small {
    @include breakpoint-down(small) {
      text-align: center !important;
    }
  }
}

/* Text color. */
.u-text-color--default {
  color: $color-default !important;
}
.u-text-color--primary {
  color: $color-primary !important;
}

.u-text-color--primary-light {
  color: $color-primary-light !important;
}

.u-text-color--secondary {
  color: $color-secondary !important;
}

.u-text-color--tertiary {
  color: $color-tertiary !important;
}

.u-text-color--white {
  color: white !important;

  &--opaque {
    color: rgba(244, 245, 245, 0.8);
  }
}

/* Text weight. */

.u-font-weight--light {
  font-weight: $font-weight-light !important;
}

.u-text-weight--normal {
  font-weight: $font-weight-base !important;
}
.u-text-weight--bold {
  font-weight: $font-weight-bold !important;
}

/* Text style. */
.u-text-style--italic {
  font-style: italic !important;
}

/* Text size. */
.u-text-size--small {
  font-size: $font-size-small !important;
}
.u-text-size--large {
  font-size: $font-size-large !important;
}
