.m-navigation {
  position: absolute;
  top: 0;
  transform: translateY($space-large);
  left: 0;
  width: 100%;
  z-index: 10000;

  & > .t-container {
    align-items: center;
    position: relative;
    display: flex;
    z-index: 100;
  }

  & svg {
    color: white;
  }

  &__logo {
    height: 100%;

    @include breakpoint-down(tiny) {
      & svg {
        width: 150px;
      }
    }
  }

  &__content {
    margin-left: auto;
    @include breakpoint-down(small) {
      display: none;
    }

    &--small {
      display: none;

      @include breakpoint-down(small) {
        display: block;
      }
    }
  }

  &__tel {
    color: white;

    & > a {
      color: white;
      text-decoration: none;
    }
  }

  &__form {
    margin-left: $space-base;
  }
}
