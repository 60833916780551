.m-card {
  padding: $space-base * 2.5 $space-large;

  &--list {
    background: radial-gradient(125.31% 125.31% at 62.64% -13.43%, #650afa 0%, #420cdd 64.58%);
    border-radius: 10px;
    height: 100%;

    & h2 {
      margin-top: $space-base * 2.25;
      color: white
    }

    & > .h-stack {
      & > p {
        color: white;
        margin-top: $space-base;
        padding-bottom: $space-base;
      }

      & > p:first-child {
        margin-top: $space-base * 2.25;
      }

      & > p:last-child {
        padding-bottom: 0;
      }

      & > p:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }
    }
  }

  &--overlap-half {
    width: calc(100% + calc(#{$width-giant} / 24));
    transform: translateX(calc(calc(#{$width-giant} / 24) * -0.5));

    @include breakpoint-down(giant) {
      width: calc(calc(100% + calc(100vw - calc(var(--layout-space) * 2)) / 24));
      transform: translateX(calc(calc(calc(100vw - calc(var(--layout-space) * 2)) / 24) * -0.5));
    }

    @include breakpoint-down(medium) {
      width: calc(calc(100% + calc(100vw - calc(var(--layout-space) * 2)) / 24));
      transform: translateX(calc(calc(calc(100vw - calc(var(--layout-space) * 2)) / 24) * -0.5));
    }

    @include breakpoint-down(small) {
      width: 100%;
      transform: none;
    }
  }

  &--fact {
    position: relative;
    z-index: 1;
    padding: $space-base * 3 $space-base * 2.5;

    &::before {
      content: '';
      height: 100%;
      width: 100%;
      background-color: $color-secondary;
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 10px;
      transform: rotate(-4.21deg) translateX($space-base * -1.5);

      @include breakpoint-down(medium) {
        transform: rotate(-4.21deg);
      }
    }

    & svg {
      fill: white;
    }
  }

  &--logos {
    flex-wrap: wrap;
    display: flex;
    gap: $space-large;
    padding: 0;
    color: $color-primary;

    & svg {
      height: 90px;
      max-height: 90px;
    }

    & > div {
      flex-basis: 100%;
    }

    @include breakpoint-up(small) {
      & > div {
        flex-basis: calc(50% - 1rem);
      }

      & svg {
        height: 120px;
        max-height: 120px;
      }
    }

    @include breakpoint-up(medium) {
      flex-wrap: nowrap;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: $space-base * 4 0;
  }
}
