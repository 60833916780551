.m-cta {
  bottom: $space-base;
  display: none;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 10000;

  &__text {
    display: block;
    transition: width 0.4s $ease-out-base;
    overflow: hidden;
    pointer-events: none;
    white-space: nowrap;
    width: var(--widthBefore);

    &::before {
      content: 'Advies';
      display: inline-block;
    }

    &::after {
      content: 'gesprek aanvragen';
      display: inline-block;
    }
  }

  @include breakpoint-down(small) {
    display: inline-block;
  }

  & button {
    transform: translateX(calc(50vw + 51%));
    width: calc(3rem + var(--widthBefore));
  }

  &--reveal {
    & button {
      transition: transform 0.4s $ease-out-base, width 0.4s $ease-out-base;
      transform: translateX(calc(50vw - calc(calc(var(--widthBefore) + 3rem) / 2) - 1rem));

      @include breakpoint-down(small) {
        box-shadow: 1px 4px 20px rgba(0,0,0, 0.15);
      }
    }
  }

  &--footer {
    position: absolute;

    & button {
      transform: translateX(0);
      width: calc(3rem + var(--widthAfter) + var(--widthBefore));

      @include breakpoint-only(small) {
        transition-duration: 0.8s;
      }
    }

    & .m-cta__text {
      width: calc(var(--widthAfter) + var(--widthBefore));

      @include breakpoint-only(small) {
        transition-duration: 0.8s;
      }
    }
  }
}
