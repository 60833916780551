/* Message. */
.m-message {
  align-items: flex-start;
  background-color: tint($color-default, 95%);
  border: 1px solid $color-default;
  border-radius: $border-radius;
  color: $color-default;
  display: flex;
  margin: $space-small 0 $space-base;
  padding: $space-base;

  & + .m-message {
    margin-top: 0;
  }

  & a {
    color: inherit;
  }

  &__icon {
    margin: 0 $space-small 0 0;
  }

  &__content {
    flex: 1 1 auto;

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  &__list {
    margin: 0;
  }

  /* Message types. */
  &--primary {
    background-color: tint($color-primary, 95%);
    border-color: tint($color-primary, 70%);
    color: shade($color-primary, 40%);
  }

  &--status {
    background-color: tint($color-success, 95%);
    border-color: tint($color-success, 70%);
    color: shade($color-success, 40%);
  }

  &--error {
    background-color: tint($color-danger, 95%);
    border-color: tint($color-danger, 70%);
    color: shade($color-danger, 40%);
  }

  &--warning {
    background-color: tint($color-warning, 95%);
    border-color: tint($color-warning, 70%);
    color: shade($color-warning, 40%);
  }
}
