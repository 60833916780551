/* Footer. */
.o-footer {
  background-color: $color-default-light;
  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & img {
      position: absolute;
      right: -200px;
      top: -40%;
      width: 550px;
      height: auto;

      @include breakpoint-down(small) {
        top: unset;
        bottom: $space-base;
        width: 350px;
        right: -150px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(48.1% 95.53% at 94.27% -2.2%, #5e10ff 0%, #301cc5 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

      @include breakpoint-down(small) {
        background: linear-gradient(187.81deg, #301cc5 14.62%, rgba(48, 28, 197, 0) 50.65%);
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint-up(medium) {
      & svg {
        height: auto;
        width: 240px;
      }
    }

    @include breakpoint-down(small) {
      margin-top: 10rem;

      & .h-text__intro {
        line-height: 1.4;
      }
    }
  }

  &__mail {
    color: white;
    display: block;

    &:hover {
      color: white;
    }

    @include breakpoint-down(small) {
      margin-top: $space-small;
    }
  }
}
