.h-list {
  &--check {
    & > li {
      display: flex;
      gap: $space-small;
      margin-top: $space-small;

      &:before {
        color: $color-tertiary;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 18 14' fill='none'%3E%3Cpath d='M6.00039 11.2L1.80039 6.99998L0.400391 8.39998L6.00039 14L18.0004 1.99998L16.6004 0.599976L6.00039 11.2Z' fill='%2358ff7b'/%3E%3C/svg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        transform: translate3d(0, $space-base * 0.2, 0);
        margin-right: $space-small;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
