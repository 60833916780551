/* Cookie bar. */
.m-cookiebar {
  background-color: $color-primary;
  color: white;
  line-height: 1.4;
  padding: $space-base 0;
  position: fixed;
  transition: transform .4s $ease-in-out-cubic;
  width: 100%;
  z-index: 200;

  &--top-bar {
    left: 0;
    top: 0;
    transform: translateY(-100%);
  }

  &--bottom-bar {
    left: 0;
    bottom: 0;
    transform: translateY(100%);
  }

  &--top-left-block {
    border-radius: $border-radius;
    box-shadow: 0 4px 12px rgba(black, .15);
    left: $space-base;
    max-width: 300px;
    top: $space-base;
    transform: translateY(-100%) translateY(calc(#{$space-base} * -1));
  }

  &--top-right-block {
    border-radius: $border-radius;
    box-shadow: 0 4px 12px rgba(black, .15);
    max-width: 300px;
    top: $space-base;
    transform: translateY(-100%) translateY(calc(#{$space-base} * -1));
    right: $space-base;
  }

  &--bottom-left-block {
    border-radius: $border-radius;
    bottom: $space-base;
    box-shadow: 0 4px 12px rgba(black, .15);
    left: $space-base;
    max-width: 300px;
    transform: translateY(100%) translateY($space-base);
  }

  &--bottom-right-block {
    border-radius: $border-radius;
    bottom: $space-base;
    box-shadow: 0 4px 12px rgba(black, .15);
    max-width: 300px;
    right: $space-base;
    transform: translateY(100%) translateY($space-base);
  }

  &--is-visible {
    transform: translateY(0%);
  }

  & h2,
  & h3,
  & p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    color: white;
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $space-small;

    .m-cookiebar[class*="-bar"] & {
      align-items: center;
      padding: 0;
    }
  }

  &__info {
    flex: 1 1 0px;
    max-width: 100%;
    min-width: 75%;
  }

  &__actions {
    flex: 0 0 auto;
    max-width: 100%;
    margin-top: $space-base;

    .m-cookiebar[class*="-bar"] & {
      @include breakpoint-up(small) {
        margin: 0 0 0 $space-small;
      }
    }
  }
}
